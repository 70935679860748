<template>
  <div class="menu-btn center-all-col" v-bind="$attrs" v-on="$listeners">
    <h3 class="center-all">{{ text }}</h3>
    <span v-if="additional" class="center-all">
      {{ additional }}
    </span>
  </div>
</template>
<script>
export default {
  name: "menu-btn",
  props: {
    large: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    block: { type: Boolean, default: true },
    text: { type: String, default: "" },
    additional: { type: String, default: "" },
    color: { type: String, default: "white" },
    textColor: { type: String, default: "textColor--text" },
  },
}
</script>
<style lang="scss" scoped>
.menu-btn.v-btn--outlined {
  padding: 30px;
  border: 2px solid !important;
  border-color: grey !important;
  background-color: white
}

.menu-btn {
  padding: 15px;
  border: 2px solid !important;
  border-color: grey !important;
  border-radius: 15px;
  background-color: white;
  color: #606664;
}

.additional {
  font-size: 12px;
}
</style>

<template>
  <div class="admin-dashboard">
    <div class="progress" v-if="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
        v-if="loading"
        class="progress"
      >
      </v-progress-circular>
    </div>
    <v-container v-else class="d-flex align-center flex-column">
      <img
        class="town-logo"
        src="../assets/town-logo.png"
        alt="קיבוץ גוש עציון"
      />
      <h3 class="mt-4">צוות צח"י</h3>
      <h3>מועצה אזורית גוש עציון</h3>
      <v-row class="mt-4">
        <v-col>
          <h1>אין אירוע מדווח</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>מוזמנים להערך בשגרה</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card
            class="btn-card-border rounded-xl"
            color="lightAzure"
            height="170"
            @click="redirect('files')"
          >
            <v-card-title primary-title class="justify-center">
              <dashboard-icons image="Group90" />
            </v-card-title>
            <v-card-text primary-title class="justify-center">
              <h3 class="text-center" color="#11A6DE">
                תיקיית חומרי <br />
                אימון
              </h3>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            class="btn-card-border rounded-xl"
            color="lightAzure"
            height="170"
            @click="redirect('status-report')"
          >
            <v-card-title primary-title class="justify-center">
              <dashboard-icons />
            </v-card-title>
            <v-card-text primary-title class="justify-center">
              <h3 class="text-center">דו”חות אירוע ישוביים</h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn text @click="loginAdmin">
            <v-img src="/images/Group91.png" maxWidth="150" />
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DashboardIcons from '@/components/DashboardIcons.vue'
import onesignalMixins from '@/mixins/onesignal'

export default {
  name: 'dashboard-user-cold',
  components: { DashboardIcons },
  mixins: [onesignalMixins],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    isWarm() {
      return this.$store.getters.isWarm
    },
  },
  methods: {
    redirect(routeName) {
      if (routeName === 'status-report') {
        return this.$router.push({ name: 'StatusReport' })
      }
      // this.$router.push({ name: routeName });
      if (routeName === 'files') {
        window.open(
          'https://drive.google.com/drive/folders/11ETeaxgS1k3VFmU8zwFE4Ml9Al2Y37YF',
          '_blank'
        )
      }
    },
    loginAdmin() {
      this.$store.commit('setAdmin', true)
      this.$router.push({ name: 'DashboardAdmin' })
    },
  },
  async mounted() {
    this.loading = true
    this.loading = false
  },
  watch: {
    isWarm() {
      if (this.isWarm) {
        this.$router.push({ name: 'DashboardUser' })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.progress {
  width: 100%;
  height: 100%;
  margin: 100px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

div ::v-deep .btn-card-border {
  border: 2px solid !important;
  border-color: var(--azure) !important;
}
</style>

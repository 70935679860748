import Sound from '@/services/sound'
import { cloneDeep } from 'lodash'
export default {
  state: {
    online: window.navigator.onLine,
    onlineQueue: [],
  },
  getters: {
    online: state => state.online,
  },
  mutations: {
    setOnline: (state, payload) => (state.online = payload),
    addToOnlineQueue: (state, payload) => state.onlineQueue.push(payload),
    shiftOnlineQueue: state => state.onlineQueue.shift(),
  },
  actions: {
    'offline/init': async ({ commit, state, dispatch }) => {
      if (!state.online || !state.onlineQueue.length) return
      await handleOnlineEvent(commit, state, dispatch)
    },
    'offline/listen': async function ({ commit, state, dispatch, getters }) {
      const $socket = this._vm.$socket
      window.addEventListener('offline', () => {
        commit('setOnline', false)
      })
      window.addEventListener('online', async () => {
        commit('setOnline', true)
        if ($socket.disconnected) {
          await connectSocket($socket.client, dispatch)
        } else await dispatch('socket/join')
        const completed = await handleOnlineEvent(commit, state, dispatch)
        if (!completed) return
        const eventsBeforeOnlineSnapshot = getters.events.reduce(
          (acc, event) => {
            acc[event._id] = 1
            return acc
          },
          {}
        )
        const todosBeforeOnlineSnapshot = cloneDeep(getters.allTodos)
        const reportsBeforeOnlineSnapshot = cloneDeep(getters.reports)
        await dispatch('event/index')
        unusedSocketsUpdates({
          commit,
          getters,
          todosBeforeOnlineSnapshot,
          reportsBeforeOnlineSnapshot,
          eventsBeforeOnlineSnapshot,
        })
      })
    },
  },
}
function connectSocket(socket, dispatch) {
  return new Promise(resolve => {
    socket.on('connect', async () => {
      console.log('Connected to server!`')
      await dispatch('socket/join')
      resolve()
    })
  })
}

async function handleOnlineEvent(commit, state, dispatch) {
  while (state.onlineQueue.length) {
    if (window.navigator.onLine === false) break
    const action = state.onlineQueue[0]
    commit('shiftOnlineQueue')
    await new Promise(resolve => {
      dispatch(action.command, { ...action.payload, onlineCallback: true })
        .then(resolve)
        .catch(resolve)
    })
  }
  return true
}

function unusedSocketsUpdates({
  commit,
  getters,
  todosBeforeOnlineSnapshot,
  reportsBeforeOnlineSnapshot,
  eventsBeforeOnlineSnapshot,
}) {
  const todosBeforeOnline = todosBeforeOnlineSnapshot.reduce((acc, todo) => {
    acc[todo._id] = todo
    return acc
  }, {})
  const reportsBeforeOnline = reportsBeforeOnlineSnapshot.reduce(
    (acc, report) => {
      acc[report._id] = report
      return acc
    },
    {}
  )

  const newTodos = []
  const todosCompleted = []
  const newReports = []
  for (const todo of getters.allTodos) {
    if (!eventsBeforeOnlineSnapshot[todo.eventId]) continue
    if (!todosBeforeOnline[todo._id]) {
      newTodos.push(todo)
    } else if (
      todo.isChecked &&
      todosBeforeOnline[todo._id].isChecked !== todo.isChecked
    ) {
      todosCompleted.push(todo)
    }
  }
  for (const report of getters.reports) {
    if (!reportsBeforeOnline[report._id]) {
      newReports.push(report)
    }
  }
  for (const todo of newTodos) {
    if (getters.isAdmin) {
      Sound.play('/sound/createTodo.mp3', getters.isMuted)
      commit('counterTodo/store', todo)
    }
  }
  for (const todo of todosCompleted) {
    if (getters.isAdmin) {
      if (todo.isChecked) {
        Sound.play('/sound/checkTodo.mp3', getters.isMuted)
        commit('counterTodo/store', todo)
      } else {
        commit('counterTodo/remove', todo)
      }
    }
  }
  for (const report of newReports) {
    if (getters.isAdmin) {
      Sound.play('/sound/createTodo.mp3', getters.isMuted)
      commit('counterTodo/store', report)
    }
  }
}

<template>
  <div>
    <v-container>
      <v-row class="justify-center">
        <v-btn
          @click.stop="openReportDialog"
          color="white"
          class="font-weight-bold py-5 end-event-btn"
        >
          שליחת דוח מצב
        </v-btn>
      </v-row>
      <categories-carousel @setCategories="setCategories" />
      <show-reports v-if="reports" />
      <todo-item
        v-for="t in todos"
        :key="t._id"
        :isEditable="false"
        :is-check-able="false"
        :checkedAt="t.checkedAt"
        :todo="t"
      />
      <div class="no-todo">
        <span v-if="!todos.length" class="d-flex justify-center red--text"
          >אין משימות</span
        >
      </div>
    </v-container>
    <send-report
      v-if="showSendReportDialog"
      v-model="showSendReportDialog"
      :customChosenEvent="$store.state.event.filtered[0]"
      :category="category.category"
    />
  </div>
</template>

<script>
import TodoItem from '../components/todo/ToDo.vue'
import CategoriesCarousel from '../components/CategoriesCarousel.vue'
import ShowReports from '../components/reports/ShowReports.vue'
import SendReport from '@/components/reports/SendReport.vue'
import Cookie from 'js-cookie'

export default {
  name: 'event-admin',
  components: {
    TodoItem,
    CategoriesCarousel,
    ShowReports,
    SendReport,
  },
  data() {
    return {
      category: '',
      showSendReportDialog: false,
    }
  },
  computed: {
    eventFiltered() {
      return this.$store.getters.eventFiltered
    },
    events() {
      return this.$store.getters.events
    },
    reports() {
      return this.$store.getters.reports.length
    },
    todos() {
      //making a hashtable of eventFilterd ID's
      const eventFilteredHashTable = this.eventFiltered.reduce((acc, cu) => {
        acc[cu._id] = true
        return acc
      }, {})
      const allCategoriesAreValid = this.category?.category === 'all'
      const currentChosenCategory = this.category?.category
      const allTodos = this.$store.getters?.allTodos
      //filtering all "To Do's" only if they are for "eventFiltered" and current category
      return allTodos?.filter(
        todo =>
          eventFilteredHashTable[todo.eventId] &&
          (allCategoriesAreValid || currentChosenCategory === todo.category)
      )
    },
  },
  methods: {
    setCategories(category) {
      this.category = category
    },
    openReportDialog() {
      this.showSendReportDialog = true
    },
  },
  mounted() {
    if (this.events.length) {
      const id = this.event ? this.event._id : this.events[0]._id
      this.$store.commit('events/chosen', [id])
      this.eventsSelected = [id]
    } else if (!this.event[0]) {
      this.$router.push({ name: 'DashboardAdmin' })
    }
    Cookie.set('department', this.category, { path: '/' })
  },
  watch: {
    category({ category }) {
      Cookie.set('department', category, { path: '/' })
    },
  },
}
</script>
<style lang="scss" scoped>
.no-todo {
  margin: 20% auto !important;
}
</style>

<template>
  <v-row>
    <v-col class="d-flex justify-center align-center">
      {{ title }}
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "userTitle",
  computed: {
    title() {
      let title = `שלום `
      title += this.$store.getters.username + " - "
      if (this.isAdmin) {
        title += `יו"ר - `
      }
      title += this.$store.getters.team
      return title
    },
    username() {
      return this.$store.getters.username
    },
    teamName() {
      return this.$store.getters.team
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
  },
}
</script>

<template>
  <div>
    <v-row class="">
      <v-col>
        <v-badge
          left
          :value="reportCounterByEventId.length"
          offset-x="10"
          offset-y="10"
          color="green"
          :content="reportCounterByEventId.length"
          class="px-3 pt-3 show-reports rounded-lg"
          style="width: 100%"
        >
          <h4>
            דו”ח מצב אחרון ({{ report.username }} - {{ categoryText }}
            {{ time }}):
          </h4>
          <p>
            {{ report.content }}
            <span
              v-if="reports.length > 1"
              style="text-align: end"
              class="text-end py-0"
            >
              <div class="text-end py-0">
                <v-btn
                  text
                  class="text-decoration-underline"
                  @click="showAllReports = true"
                  >עוד</v-btn
                >
              </div>
            </span>
          </p>
        </v-badge>
      </v-col>
    </v-row>
    <all-reports-dialog
      v-if="showAllReports"
      v-model="showAllReports"
      :reports="reports"
    />
  </div>
</template>

<script>
import Times from '@/helpers/times'
import AllReportsDialog from '../dialogs/AllReportsDialog.vue'
import { categories } from '@/helpers/categoriesAndTypes'

export default {
  name: 'show-reports',
  data() {
    return {
      showAllReports: false,
    }
  },
  components: { AllReportsDialog },
  computed: {
    categoryText() {
      return categories.find(
        category => category.category == this.report.category
      )?.text
    },
    newReportCounter() {
      return this.$store.getters.newReportCounter
    },
    reports() {
      return this.$store.getters.reports.map(r => {
        if (this.newReportCounter.map(r => r._id).includes(r._id)) {
          r.isNew = true
        } else {
          r.isNew = false
        }
        return r
      })
    },
    report() {
      return this.$store.getters.reports[0]
    },
    time() {
      return Times?.extractTimeString(this?.report?.createdAt)
    },
    reportCounterByEventId() {
      return this.newReportCounter.filter(c =>
        this.reports.map(r => r.eventId).includes(c.eventId)
      )
    },
  },
  methods: {},
  beforeDestroy() {
    if (this.reportCounterByEventId.length === 1) {
      this.$store.commit('counterReport/destroyByEventId', [
        ...this.reports.map(r => r.eventId),
      ])
    }
  },
}
</script>

<style lang="scss" scoped>
.show-reports {
  border: 1px solid var(--orange);
  background-color: var(--background);
}
</style>

<template>
  <div>
    <my-dialog
      v-model="eventModel"
      :title="eventTitle"
      :subtitle="'תקציר האירוע'"
      @actionBtnRight="submit"
      @actionBtnLeft="eventModel = false"
      :textBtnRight="isNew ? 'פתיחת ארוע' : 'שמירה'"
      textBtnLeft="ביטול"
      :disabled="disabled"
    >
      <template v-slot:content>
        <v-textarea
          background-color="white"
          outlined
          v-model="content"
        ></v-textarea>
        <div class="d-flex justify-space-between">
          <v-switch
            dark
            v-model="isPushNotification"
            color="primary"
            :label="
              isPushNotification
                ? 'לשלוח הודעות לכולם'
                : 'לא לשלוח הודעות לכולם'
            "
          ></v-switch>
          <v-icon color="orange" @click="openMap">mdi-map-marker</v-icon>
        </div>
      </template>
    </my-dialog>
    <my-dialog
      v-model="locationModel"
      :subtitle="'בחירת מיקום'"
      :subtitleClasses="''"
      @actionBtnRight="locationModel = false"
      :textBtnLeft="''"
      :textBtnRight="'שלח מיקום'"
      :primaryIsLeftBtn="false"
    >
      <template v-slot:content>
        <GmapMap
          :center="centerCoordinates"
          :zoom="15"
          map-type-id="terrain"
          style="height: 55vh"
          class="mb-4"
          ref="selectMap"
        >
          <GmapMarker
            v-if="selectedCoordinates"
            :position="selectedCoordinates"
          />
        </GmapMap>
      </template>
    </my-dialog>
  </div>
</template>

<script>
import MyDialog from './MyDialog.vue'
import { gmapApi } from 'vue2-google-maps'
export default {
  name: 'open-event-dialog',
  components: { MyDialog },
  props: {
    value: Boolean,
    category: String,
    isNew: { type: Boolean, default: true },
  },

  data() {
    return {
      disabled: false,
      isPushNotification: true,
      locationModel: false,
      thisCategory: null,
      content: null,
      //default location set to Jerusalem
      centerCoordinates: {
        lat: 31.7683,
        lng: 35.2137,
      },
      selectedCoordinates: {},
    }
  },
  computed: {
    eventModel: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    event() {
      return this.$store.getters.event
    },
    username() {
      return this.$store.getters.username
    },
    eventType() {
      return this.$store.getters.eventTypes
    },
    eventTitle() {
      // return this.item ? `האם לפתוח אירוע ${this.item.text}?` : null
      return this.eventType.find(ev => ev.name === this.category)?.text
    },
    google: gmapApi,
  },
  methods: {
    async submit() {
      this.disabled = true
      const dispatch = this.isNew ? 'event/store' : 'event/update'
      let { lat, lng } = this.selectedCoordinates
      if (!lat || !lng) {
        lng = this.centerCoordinates.lng
        lat = this.centerCoordinates.lat
      }

      await this.$store.dispatch(dispatch, {
        category: this.thisCategory,
        openedBy: this.username,
        location: {
          type: 'Point',
          coordinates: [[lat, lng]],
        },
        content: this.content,
        isPushNotification: this.isPushNotification,
        _id: this.id,
      })
      this.eventModel = false
    },
    setCenterCoordinatesToUser: function () {
      navigator.geolocation.getCurrentPosition(res => {
        this.centerCoordinates = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        }
      })
    },
    handleMapClick(e) {
      const data = {
        lat: e.latLng?.lat(),
        lng: e.latLng?.lng(),
      }
      this.selectedCoordinates = data
    },
    openMap() {
      this.locationModel = true
      setTimeout(async () => {
        const map = await this.$refs.selectMap.$mapPromise
        map.addListener('click', this.handleMapClick)
      }, 0)
    },
  },
  mounted() {
    this.thisCategory = this.category
    if (!this.isNew) {
      this.content = this.event.content
      this.thisCategory = this.event.category
      this.location = this.event.location
      this.id = this.event._id
    }
    this.setCenterCoordinatesToUser()
  },
}
</script>

<style></style>

<template>
  <my-dialog
    v-model="model"
    @actionBtnLeft="model = false"
    @actionBtnRight="sendStatusReport"
    :textBtnLeft="'ביטול'"
    :textBtnRight="'שליחה'"
    :primaryIsLeftBtn="false"
  >
    <template v-slot:content>
      <v-textarea
        class="my-n10"
        :height="400"
        background-color="white"
        color="black"
        outlined
        v-model="content"
        placeholder="פה כותבים את דו”ח המצב"
      ></v-textarea>
    </template>
  </my-dialog>
</template>

<script>
import MyDialog from '../dialogs/MyDialog.vue'
export default {
  name: 'send-report',
  components: { MyDialog },
  props: {
    value: { type: Boolean, default: false },
    customChosenEvent: { type: Object },
    category: { type: String },
  },
  data() {
    return {
      content: '',
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    username() {
      return this.$store.getters.username
    },
    event() {
      return this.$store.getters.event
    },
  },
  methods: {
    async sendStatusReport() {
      let report = {
        content: this.content,
        username: this.username,
        createdByAdmin: this.isAdmin,
        eventId: this.customChosenEvent?._id || this.event._id,
        category: this.category,
      }
      await this.$store.dispatch('report/store', report)
      this.model = false
    },
  },
}
</script>

// eslint-disable-next-line no-unused-vars
import OneSignalVue from 'onesignal-vue'

const onesignalMixins = {
  data: function () {
    return {
      playerId: null, // or initialize it with a default value
    }
  },
  beforeCreate() {
    this.$OneSignal.showSlidedownPrompt()
    //this.$OneSignal.showNativePrompt()
  },
  mounted() {
    this.isPushNotificationsEnabledVerbose()
  },
  computed: {
    async isRegistered() {
      return await this.$OneSignal.isPushNotificationsEnabled()
    },
  },
  methods: {
    getPlayerId() {
      this.$OneSignal.getUserId().then(userId => {
        this.playerId = userId
      })
    },
    showPrompts() {
      this.$OneSignal.showSlidedownPrompt()
      this.$OneSignal.showNativePrompt()
    },
    async unregister() {
      await this.$store.dispatch('onesignal/unregister', {
        playerId: this.playerId,
      })
    },
    isPushNotificationsEnabledVerbose() {
      console.log('isPushNotificationsEnabledVerbose()')
      Promise.all([
        this.$OneSignal.isPushNotificationsEnabled(),
        this.$OneSignal.getUserId(),
        // this.$OneSignal.getRegistrationId(),
        this.$OneSignal.getNotificationPermission(),
        // this.$OneSignal.isOptedOut(),
        // this.$OneSignal.context.serviceWorkerManager.getActiveState(),
      ])
        .then(
          ([
            isSubscribed,
            userId,
            registrationId,
            notificationPermission,
            optedOut,
            serviceWorkerActive,
          ]) => {
            console.log('Is Completely Subscribed:', isSubscribed)
            console.log('')
            console.log('What is our OneSignal user ID?', userId)
            console.log('What is our push subscription token?', registrationId)
            console.log(
              'What is the notification permission status?',
              notificationPermission
            )
            console.log('Are you manually opted out?', optedOut)
            console.log(
              "Is a service worker registered and active? (should be false on Safari, otherwise should be 'Worker A (Main)')?",
              serviceWorkerActive
            )
            console.log('What is the current URL of this page?', location.href)
            console.log(
              "What environment does OneSignal think it's in?",
              this.$OneSignal.sdkEnvironment.getWindowEnv()
            )
          }
        )
        .catch(e => {
          console.error('Issue determining whether push is enabled:', e)
        })
    },
  },
}

export default onesignalMixins

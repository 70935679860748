<script>
import { openDB } from 'idb'
import _ from 'lodash'

export default {
  computed: {
    fileLinks: function () {
      return this.$store.getters.allLinksMap
    },
    linksNotFile: function () {
      return this.$store.getters.linksNotFile
    },
    envTest: function () {
      return process.env.VUE_APP_FILES
    },
  },
  watch: {
    async fileLinks(filesObj) {
      await updateFileLinksInDatabase(filesObj, this)
    },
  },
}
export async function updateFileLinksInDatabase(newVal, context) {
  const filesObj = _.clone(newVal)

  context.linksNotFile.forEach(link => {
    delete filesObj[link]
  })
  const db = await openDB('fileLinks', 1, {
    upgrade(db) {
      db.createObjectStore('fileLinks', {
        keyPath: 'id',
      })
    },
  })
  const allFiles = await db.getAll('fileLinks')
  const transaction = db.transaction(['fileLinks'], 'readwrite')
  const objectStore = transaction.objectStore('fileLinks')

  // delete files not in filesObj
  for (const fileObj of allFiles) {
    if (!(fileObj.id in filesObj)) {
      await objectStore.delete(fileObj.id)
      console.log('File deleted from the database:', fileObj.id)
    }
  }

  // add files not in db but in filesObj
  for (const fileLink in filesObj) {
    let fileObj = await getKeyFromDB(fileLink)
    if (!fileObj) {
      try {
        let response
        const regexDrive = /^https:\/\/(drive|docs)\.google\.com/
        const isDrive = regexDrive.test(fileLink)
        try {
          if (isDrive) {
            response = await fetch(
              `/event/file-link/${encodeURIComponent(fileLink)}`
            )
          } else response = await fetch(fileLink)
        } catch (e) {
          console.log(e)
        }

        if (!response || !shouldDownloadFile(response)) {
          context.$store.commit('todo/linkNotFile', fileLink)
          continue
        }
        const blob = await response.blob()
        const transaction = db.transaction(['fileLinks'], 'readwrite')
        const objectStore = transaction.objectStore('fileLinks')
        await objectStore.add({
          id: fileLink,
          file: new File([blob], fileLink, { type: blob.type }),
        })

        console.log('File added to the database:', fileLink)
        await transaction.complete
      } catch (error) {
        console.error('An error occurred:', error)
      }
    }
  }

  await transaction.complete
}

export async function getKeyFromDB(key) {
  const db = await openDB('fileLinks', 1)
  const tx = db.transaction('fileLinks', 'readonly')
  const store = tx.objectStore('fileLinks')
  const result = await store.get(key)
  await tx.done
  return result
}
function filesToDownload() {
  const fileString = process.env.VUE_APP_FILES
  if (!fileString) return {}
  const files = fileString.split(',')
  return files.reduce((acc, file) => {
    acc[file] = 1
    return acc
  }, {})
}
const filesObj = filesToDownload()
function shouldDownloadFile({ headers }) {
  const contentDisposition = headers.get('x-file-type')
  if (!contentDisposition) return
  if (contentDisposition in filesObj) {
    return true
  }
  return false
}
</script>

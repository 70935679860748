export default {
  state: {
    newTodoCounter: [],
    newReportCounter: [],
  },
  getters: {
    //todo counters
    newTodoCounter: state => state.newTodoCounter,
    newTodoCounterSum: state => state.newTodoCounter.length,
    newTodoCounterByCategory: state => category =>
      state.newTodoCounter.filter(c => c.category === category).length,
    //reports counters
    newReportCounter: state => state.newReportCounter,
    newReportCounterSum: state => state.newReportCounter.length,
  },
  mutations: {
    //store one counter
    'counterTodo/store': (state, payload) => {
      if (!state.newTodoCounter.find(t => t._id === payload._id)) {
        state.newTodoCounter.push(payload)
      } else {
        state.newTodoCounter = state.newTodoCounter.map(item => {
          if (item._id === payload._id) {
            return { ...item, ...payload }
          }
          return item
        })
      }
    },
    //destroys one counter todo
    'counterTodo/remove': (state, todo) =>
      (state.newTodoCounter = state.newTodoCounter.filter(item => {
        return item._id !== todo._id
      })),
    //destroys all counter todo
    'counterTodo/destroyAll': state => (state.newTodoCounter = []),
    //destroys counter todo by category
    'counterTodo/destroyByCategory': (state, category) =>
      (state.newTodoCounter = state.newTodoCounter.filter(item => {
        return item.category !== category
      })),

    //store one report
    'counterReport/store': (state, payload) =>
      state.newReportCounter.push(payload),
    //destroys all counter report
    'counterReport/destroy': state => (state.newReportCounter = []),
    //destroys one counter report by category
    'counterReport/destroyByEventId': (state, ids = []) => {
      state.newReportCounter = state.newReportCounter.filter(item => {
        return !ids.includes(item.eventId)
      })
    },
  },
}

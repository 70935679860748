<template>
  <div id="background" class="ma-0">
    <v-img
      src="/images/Ellipse7.svg"
      id="background"
      class="ma-0 d-flex justify-center align-center"
      :maxWidth="maxWidthBackground"
    >
      <v-img
        :src="`/images/${image}.svg`"
        id="image"
        :maxWidth="maxWidthImage"
        class="ma-0"
      ></v-img>
    </v-img>
  </div>
</template>

<script>
export default {
  name: 'dashboardIcons',
  props: {
    maxWidthBackground: { type: [String, Number], default: '75' },
    maxWidthImage: { type: [String, Number], default: '40' },
    image: { type: String, default: 'Frame' },
  },
}
</script>
<style lang="scss" scoped>
#image {
  margin: 20% auto !important;
}
</style>

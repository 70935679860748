<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-center align-center">
        <h2>ארוע פתוח:</h2>
      </v-col>
    </v-row>
    <multi-event />
    <v-row>
      <v-col class="d-flex justify-center align-center">
        <h4>נא לבחור את התא בצח"י בו תתפקדו באירוע:</h4>
      </v-col>
    </v-row>
    <v-row v-for="(item, i) in items" :key="i" justify="center">
      <v-col cols="10" class="my-0 py-2">
        <menu-btn :text="item.text" @click="toTodoPage(item.category)" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex align-center flex-column">
        <v-btn text @click="loginAdmin">
          <v-img src="/images/Group91.png" maxWidth="150" />
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MenuBtn from '@/components/MenuBtn.vue'
import MultiEvent from '../components/MultiEvent.vue'
import { categories } from '@/helpers/categoriesAndTypes'
import onesignalMixins from '@/mixins/onesignal'

export default {
  name: 'dashboard-user',
  components: { MenuBtn, MultiEvent },
  mixins: [onesignalMixins],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    events() {
      return this.$store.getters.events
    },
    event() {
      return this.$store.getters.event
    },
    isWarm() {
      return this.$store.getters.isWarm
    },
    items() {
      //return only non admin categories
      return categories.filter(c => !c.isAdmin)
    },
    isAuth() {
      return this.$store.getters.isAuth
    },
  },
  methods: {
    toTodoPage(category) {
      this.$router.push({ path: `/todo/${category}` })
    },
    loginAdmin() {
      this.$store.commit('setAdmin', true)
      this.$router.push({ name: 'DashboardAdmin' })
    },
  },
  watch: {
    isWarm() {
      if (!this.isWarm && this.isAuth)
        return this.$router.push({ name: 'DashboardUserCold' })
    },
  },
}
</script>

import Notifications from '@/services/notifications'
import axios from 'axios'
function extractResponses(residents) {
  const extractedResponses = []

  residents.forEach(resident => {
    const { FirstName, LastName, Phone, Responses } = resident

    if (Responses) {
      Responses.forEach(response => {
        const { ResponseStatusName, Created, Comment, StatusId, Id } = response
        extractedResponses.push({
          FirstName,
          LastName,
          Phone,
          ResponseStatusName,
          Created,
          StatusId,
          Comment,
          Id,
        })
      })
    } else {
      extractedResponses.push({
        FirstName,
        LastName,
        Phone,
        ResponseStatusName: 'לא נמסרה תשובה',
        Created: Date.now(),
        StatusId: 0,
        Comment: '',
      })
    }
  })

  return extractedResponses
}

function generateFilterFunction(
  filterCity,
  filterQuarter,
  filterResponseStatus,
  chosenMarkers
) {
  return function filterItems(item) {
    const cityMatch = !filterCity || item.City === filterCity
    const quarterMatch = !filterQuarter || item.Quarter === filterQuarter
    const responseStatusMatch =
      !filterResponseStatus ||
      extractResponses(item.Residents).some(
        response => response.ResponseStatusName === filterResponseStatus
      )
    const markerMatch =
      chosenMarkers.length === 0 ||
      Object.values(chosenMarkers).every(marker => !marker) ||
      chosenMarkers[item.Location]

    return cityMatch && quarterMatch && responseStatusMatch && markerMatch
  }
}
function distinctBy(items, extractor) {
  const uniqueValues = new Set()
  return items.reduce((acc, item) => {
    const value = extractor(item)
    if (!uniqueValues.has(value)) {
      uniqueValues.add(value)
      acc.push({ text: value, value })
    }
    return acc
  }, [])
}

function distinctSelections(items, value) {
  return [{ text: 'ללא', value: '' }, ...distinctBy(items, item => item[value])]
}

function distinctResponseStatuses(homes) {
  const uniqueValues = new Set()
  for (const home of homes) {
    for (const response of extractResponses(home.Residents)) {
      if (!uniqueValues.has(response.ResponseStatusName))
        uniqueValues.add(response.ResponseStatusName)
    }
  }
  return [
    { text: 'ללא', value: '' },
    ...Array.from(uniqueValues).map(value => ({ text: value, value })),
  ]
}

async function avukaData() {
  try {
    return (
      await axios.get('/event/avuka', {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            'authenticToken'
          )}`,
        },
      })
    )?.data
  } catch (e) {
    Notifications.errorToast('', 'משהו השתבש')
    return 'no event'
  }
}

function getMarkers(items, chosenMarkers) {
  const locations = items
    .filter(item => item.Location)
    .map(item => {
      const { Location } = item

      return {
        Location,
        StatusIds: new Set(
          extractResponses(item.Residents).map(response => response.StatusId)
        ),
      }
    })
  // Step 2: Convert the aggregated object to the desired marker format
  return Object.values(locations).map(locationData => {
    return {
      Location: locationData.Location,
      icon: getIcon(Array.from(locationData.StatusIds)),
      scale: chosenMarkers[locationData.Location]
        ? { width: 100, height: 100 }
        : { width: 50, height: 50 },
    }
  })
}

function getIcon(array) {
  // Sort the array to handle combinations in a consistent order
  const sortedArray = array.slice().sort()
  const icon = getStatusColor(sortedArray.join('-'))

  // Determine the icon based on the sorted combination

  return `/map/${icon}.svg`
}

function getStatusColor(status) {
  let icon
  switch (status) {
    case '0':
      icon = 'blue'
      break
    case '0-1':
      icon = 'blue-green'
      break
    case '0-2':
      icon = 'blue-orange'
      break
    case '0-3':
      icon = 'red-blue'
      break
    case '0-1-2':
      icon = 'blue-orange-green'
      break
    case '0-1-3':
      icon = 'blue-red-green'
      break
    case '0-2-3':
      icon = 'blue-red-orange'
      break
    case '0-1-2-3':
      icon = 'all'
      break
    case '1':
      icon = 'green'
      break
    case '2':
      icon = 'orange'
      break
    case '3':
      icon = 'red'
      break
    case '1-2':
      icon = 'orange-green'
      break
    case '1-3':
      icon = 'red-green'
      break
    case '2-3':
      icon = 'red-orange'
      break
    case '1-2-3':
      icon = 'red-orange-green'
      break
  }
  return icon
}

function generateHomeStatusNumber(home) {
  const responses = extractResponses(home.Residents)

  return responses.map(response => ({
    status: response.StatusId === 1 ? 1 : 0,
    color: getStatusColor(`${response.StatusId}`),
  }))
}

function generateHomeStatus(home) {
  const responses = extractResponses(home.Residents)
  let greensCounts = 0
  let orangeCounts = 0
  for (const response of responses) {
    const { StatusId } = response
    if (StatusId === 3)
      return { status: 'מצוקה', color: getStatusColor('3'), StatusId: 3 }
    if (StatusId === 1) {
      greensCounts++
    }
    if (StatusId === 2) {
      orangeCounts++
    }
  }
  if (greensCounts === responses.length) {
    return { status: 'תקין', color: getStatusColor('1'), StatusId: 1 }
  }
  if (orangeCounts === responses.length) {
    return { status: 'לא ידוע', color: getStatusColor('2'), StatusId: 2 }
  }
  return { status: '', color: 'yellow', StatusId: 4 }
}

export default {
  extractResponses,
  generateFilterFunction,
  avukaData,
  distinctSelections,
  getMarkers,
  distinctResponseStatuses,
  generateHomeStatus,
  generateHomeStatusNumber,
}

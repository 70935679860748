import myAxios from '@/services/myAxios'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    'onesignal/register': async (context, payload) => {
      await myAxios.post('/web-push/register', payload)
    },
    'onesignal/unregister': async (context, payload) => {
      await myAxios.post('/web-push/unregister', payload)
    },
  },
}

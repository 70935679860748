<template>
  <v-navigation-drawer class="drawer" app right v-model="openModal">
    <v-spacer class="mb-16"></v-spacer>
    <div class="item-list">
      <!-- name -->
      <span
        class="white--text subtitle-1 mb-4 d-flex justify-space-between align-center"
      >
        <span>
          {{ title }}
        </span>
        <v-btn icon @click="setNotifications">
          <v-icon
            :style="`--disabled-not-color:${isNotifications ? 'green' : 'red'}`"
            :color="isNotifications ? 'green' : 'red'"
          >
            {{ isNotifications ? 'mdi-bell' : 'mdi-bell-off-outline' }}
          </v-icon>
        </v-btn>
        <v-btn icon @click="setVolume">
          <v-icon :color="isMuted ? 'red' : 'green'">
            {{ isMuted ? 'mdi-volume-variant-off' : 'mdi-volume-high' }}
          </v-icon>
        </v-btn>
      </span>

      <!-- links -->
      <div
        :class="{ 'current-page': $route.path === item.route }"
        class="mb-3 btn-wrapper"
        v-for="(item, i) in items"
        :key="i"
      >
        <v-btn
          @click="$router.push(item.route)"
          elevation="0"
          class="py-6 button"
          >{{ item.title }}
        </v-btn>
      </div>
      <v-btn
        color="red"
        @click="logout"
        elevation="0"
        class="py-6 white--text button"
      >
        יציאה
      </v-btn>
    </div>
  </v-navigation-drawer>
</template>

<script>
import onesignalMixins from '@/mixins/onesignal'

export default {
  name: 'app-drawer',
  props: { value: Boolean },
  mixins: [onesignalMixins],
  data() {
    return {
      excelDialog: false,
      isNotifications: false,
      disabledNotifications: false,
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    team() {
      return this.$store.getters.team
    },
    items() {
      if (!this.isAdmin) return []
      const buttonsData = [
        {
          title: 'מסך אירועים',
          route: '/dashboard-admin',
        },
        {
          title: 'ניהול אירוע בזמן אמת',
          route: '/todo/headQuaters',
        },
        {
          title: 'דו”חות',
          route: '/event-admin',
        },
        {
          title: 'דו"חות סיכום',
          route: '/status-report',
        },
      ]
      if (['שדה בר', 'נוקדים', 'כפר אלדד'].includes(this.team)) {
        buttonsData.push({
          title: 'אשכולות',
          route: '/avuka',
        })
      }

      return buttonsData.filter((/*{ route: routeName }*/) => {
        return true
      })
    },
    title() {
      let title = ``
      title += this.$store.getters.username + ' - '
      if (this.isAdmin) {
        title += `יו"ר - `
      }
      title += this.$store.getters.team
      return title
    },
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    isMuted() {
      return this.$store.getters.isMuted
    },
  },
  methods: {
    async checkNotificationPromise() {
      const isGranted = await Notification.requestPermission()
      this.isNotifications = isGranted === 'granted'
      this.disabledNotifications = isGranted !== 'default'
    },
    async setNotifications() {
      await Notification.requestPermission()
      this.showPrompts()
    },
    setVolume() {
      this.$store.commit('toggleIsMuted')
    },
    goRoute(r) {
      this.$router.push({ name: r })
      this.openModal = false
    },
    async logout() {
      this.openModal = false
      await this.$store.dispatch('auth/logout', {
        toastOptions: { disabled: true },
      })
      await this.$router.push({ name: 'Login' })
    },
  },
  mounted() {
    this.checkNotificationPromise()
  },
}
</script>

<style lang="scss" scoped>
button.v-btn[disabled] {
  opacity: 0.7;
  color: var(--primary) !important;
}
.theme--light.v-btn.v-btn--disabled .v-icon,
.theme--light.v-btn.v-btn--disabled .v-btn__loading {
  color: var(--disabled-not-color) !important;
}
.drawer {
  background-color: var(--grey-transparent) !important;

  .item-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 0 20px;

    .button {
      border-radius: 5px;
      font-size: 100%;
      font-weight: 600;

      width: 100%;
    }

    .btn-wrapper {
      padding: 3px;
    }

    .current-page {
      background: linear-gradient(
        270deg,
        var(--primary) 0%,
        var(--orange) 100%
      );
      height: fit-content;
      border-radius: 5px;
    }
  }
}
</style>

import * as XLSX from 'xlsx/xlsx.mjs'
import I18n from '../i18n.js'
const $t = I18n.messages.he
import myAxios from './myAxios.js'
import dayjs from 'dayjs'
import { categoriesMap } from '@/helpers/categoriesAndTypes.js'

export default async function downloadExcel(_id) {
  const { data } = await myAxios(`/event/status-reports/show/${_id}`)
  data.logs = data.logs.map(log => ({
    [$t.statusReport.type]: $t.statusReport[log.type],
    [$t.statusReport.content]: log.content,
    [$t.statusReport.event]: `${$t.eventCategories[log.event.category]} ${
      log.event.content
    }`,
    [$t.statusReport.createdAt]: dayjs(log.createdAt).format(
      'DD-MM-YYYY HH:mm:ss:SSS'
    ),
    [$t.statusReport.user]: log.user || '',
    [$t.statusReport.department]: `${
      categoriesMap[log.department]?.text || ''
    }`,
  }))
  // Create a new workbook
  const workbook = XLSX.utils.book_new()

  // Convert data to a worksheet
  const worksheet = XLSX.utils.json_to_sheet(data.logs)

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

  // Convert the workbook to a buffer
  const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

  // Convert the buffer to a blob
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  // Create a link to download the file
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = data.title + '.xlsx'
  document.body.appendChild(link)

  // Click the link to start the download
  link.click()

  // Clean up the link
  document.body.removeChild(link)
}

import axios from 'axios'
import genericsServices from '@/services/generics'
import ObjectID from 'bson-objectid'

export default {
  state: {
    event: null, //chosen event id
    events: [],
    filtered: [],
    linksNotFile: [],
    statusReports: [],
    eventTypes: [],
  },
  getters: {
    eventTypes: state => state.eventTypes,
    reports: state => {
      let reports = state.filtered
        ?.map(event => event.reports)
        .flat()
        .sort(genericsServices.sortByKey('createdAt'))
      return reports || []
    },
    todos: (state, getters) => getters.event?.todos || [],
    events: state => state.events,
    event: state =>
      state.events.find(e => e._id === state.event) || state.events[0] || null,
    eventFiltered: state => state.filtered,
    allTodos: state => {
      return state.events
        .map(event =>
          event.todos.map(todo => ({ ...todo, eventId: event._id }))
        )
        .flat()
        .sort((a, b) => a.index - b.index)
    },
    allLinksMap: (state, getters) => {
      return getters.allTodos.reduce((obj, item) => {
        if (item.link && !Number(item.link)) obj[item.link] = 1
        return obj
      }, {})
    },
    linksNotFile: state => state.linksNotFile,
    statusReports: state => state.statusReports,
    toTodoByDepartment: (_, { allTodos }) => {
      return allTodos.reduce((obj, event) => {
        if (!obj[event.category]) obj[event.category] = []
        obj[event.category].push(event)
        return obj
      }, {})
    },
  },
  mutations: {
    //clearEvents
    clearEvents: state => {
      state.event = null
      state.events = []
      state.filtered = []
    },
    //sets all events
    'events/set': (state, payload) => {
      state.events = payload
      state.filtered = [...state.events]
    },
    //sets chosen events
    'events/chosen': (state, ids) => {
      state.filtered =
        !ids?.length < 0
          ? []
          : state.events.filter(e => ids.indexOf(e._id) !== -1)
    },
    //sets one event
    'event/set': (state, payload) => {
      state.event = payload ? payload._id : null
    },
    //sets one event
    'event/findByIdAndSet': (state, id) => {
      state.event = id ?? null
    },
    //filters the event's array by event's key and event's val
    'events/filter': (state, { key, val }) => {
      state.filtered = !val
        ? [...state.events]
        : state.events.filter(f => f[key] === val)
    },
    //store one event
    'event/store'(state, payload) {
      if (!state.events.find(e => e._id === payload._id)) {
        state.events.push(payload)
      }
      this.commit('setIsWarm', true)
    },
    //destroys one event
    'event/destroy'(state, id) {
      state.events = state.events.filter(item => {
        return item._id !== id
      })
      if (state.event === id) {
        state.event = null
      }
      if (!state.events.length) {
        this.commit('setIsWarm', false)
      }
    },
    //updates one event
    'event/update': (state, payload) => {
      state.events = state.events.map(item => {
        if (item._id === payload._id) {
          return { ...item, ...payload }
        }
        return item
      })
    },
    //store one todo
    'todo/store'(state, payload) {
      state.events.find(e => e._id === payload.eventId)?.todos.push(payload)
    },
    //destroys one todo
    'todo/destroy': (state, { eventId, todoId }) => {
      state.events = state.events.map(e => {
        if (e._id === eventId) {
          const todos = e.todos.filter(item => {
            return item._id !== todoId
          })
          e['todos'] = todos
        }
        return e
      })
    },
    //updates one todo
    'todo/update': (state, payload) => {
      state.events = state.events.map(e => {
        if (e._id === payload.eventId) {
          const todos = e.todos.map(item => {
            if (item._id === payload.todoId) {
              return { ...item, ...payload }
            }
            return item
          })
          e['todos'] = todos
        }
        return e
      })
    },
    'todo/linkNotFile': (state, payload) => {
      state.linksNotFile.push(payload)
    },
    'todo/updateIndexes': (state, changedIndexes) => {
      for (const changedIndex of changedIndexes) {
        const event = state.events.find(e => e._id === changedIndex.event)
        event.todos = event.todos.map(todo => {
          if (todo._id === changedIndex._id) {
            todo.index = changedIndex.index
          }
          return todo
        })
      }
    },
    //store one report
    'report/store'(state, payload) {
      state.events.find(e => e._id === payload.eventId).reports.push(payload)
    },
    //destroys one report
    'report/destroy': (state, { eventId, reportId }) => {
      state.events = state.events.map(e => {
        if (e._id === eventId) {
          const reports = e.reports.filter(item => {
            return item._id !== reportId
          })
          e['reports'] = reports
        }
        return e
      })
    },
    //updates one report
    'report/update': (state, payload) => {
      state.events = state.events.map(e => {
        if (e._id === payload.eventId) {
          const reports = e.reports.map(item => {
            if (item._id === payload.reportId) {
              return { ...item, ...payload }
            }
            return item
          })
          e['reports'] = reports
        }
        return e
      })
    },
    'statusReport/set': (state, payload) => {
      state.statusReports = payload
    },
    'eventType/set': (state, payload) => {
      state.eventTypes = payload
    },
  },
  actions: {
    //fetch all events
    'event/index': async context => {
      const { data } = await axios.get('/event?isActive=true')
      context.commit('events/set', data)
      if (!context.state.event) {
        context.commit('event/set', data[0])
      }
    },
    //fetch one event by id
    'event/show': async (context, id) => {
      let { data } = await axios.get('/event/' + id)
      context.commit('event/set', data)
    },
    //stores one event
    'event/store': async (context, payload) => {
      if (!context.rootGetters.online) {
        const _id = ObjectID().toString()
        context.commit('event/store', { ...payload, _id })
        context.commit('event/set', { ...payload, _id })
        return context.commit('addToOnlineQueue', {
          command: 'event/store',
          payload: { ...payload, _id },
        })
      }
      const { onlineCallback } = payload
      if (onlineCallback) delete payload.onlineCallback
      let { data } = await axios.post('/event', { ...payload })
      context.dispatch('socket/event/store', {
        event: data,
        isPushNotification: payload.isPushNotification,
      })
      if (onlineCallback) return
      context.commit('event/store', data)
      context.commit('event/set', data)
    },
    //destroys one event
    'event/destroy': async (context, payload) => {
      const { _id, onlineCallback } = payload
      if (onlineCallback) delete payload.onlineCallback
      if (!context.rootGetters.online) {
        context.commit('event/destroy', _id)
        return context.commit('addToOnlineQueue', {
          command: 'event/destroy',
          payload,
        })
      }
      await axios.delete('/event/' + _id)
      context.dispatch('socket/event/destroy', _id)
      if (onlineCallback) return
      context.commit('event/destroy', _id)
    },
    //updates one event by its id
    'event/update': async (context, payload) => {
      const { onlineCallback } = payload
      if (onlineCallback) delete payload.onlineCallback
      if (!context.rootGetters.online) {
        context.commit('event/update', payload)
        context.commit('event/set', { ...context.getters.event, ...payload })
        return context.commit('addToOnlineQueue', {
          command: 'event/update',
          payload,
        })
      }
      await axios.put('/event/' + payload._id, payload)
      context.dispatch('socket/event/update', payload)
      if (onlineCallback) return
      context.commit('event/update', payload)
      context.commit('event/set', { ...context.getters.event, ...payload })
    },
    //close one event by id
    'event/close': async (context, payload) => {
      const { _id, onlineCallback } = payload
      if (onlineCallback) delete payload.onlineCallback
      const events = context.getters.events
      if (!context.rootGetters.online) {
        context.commit('event/destroy', _id)
        if (!events.length) {
          context.commit('event/set', null)
          context.commit('setIsWarm', false)
        } else {
          context.commit('event/set', events[0])
        }
        return context.commit('addToOnlineQueue', {
          command: 'event/close',
          payload,
        })
      }
      await axios.post('/event/close/' + _id)

      context.dispatch('socket/event/close', _id)
      if (onlineCallback) return
      context.commit('event/destroy', _id)
      if (!events.length) {
        context.commit('event/set', null)
        context.commit('setIsWarm', false)
      } else {
        context.commit('event/set', events[0])
      }
    },
    //stores one todo
    'todo/store': async (context, payload) => {
      if (!context.rootGetters.online) {
        const _id = ObjectID().toString()
        context.commit('todo/store', { ...payload, _id })
        return context.commit('addToOnlineQueue', {
          command: 'todo/store',
          payload: { ...payload, _id },
        })
      }
      const { onlineCallback } = payload
      if (onlineCallback) delete payload.onlineCallback
      let { data } = await axios.post(`/event/${payload.eventId}/todo`, payload)
      context.dispatch('socket/todo/store', {
        eventId: payload.eventId,
        ...data,
      })
      if (onlineCallback) return
      context.commit('todo/store', { eventId: payload.eventId, ...data })
    },
    //updates one todo to check
    'todo/check': async (context, payload) => {
      const { onlineCallback } = payload
      if (onlineCallback) delete payload.onlineCallback
      if (!context.rootGetters.online) {
        payload['todoId'] = payload._id
        payload['checkedAt'] = new Date().toJSON()
        context.commit('todo/update', payload)
        return context.commit('addToOnlineQueue', {
          command: 'todo/check',
          payload,
        })
      }
      await axios.post(
        `/event/${payload.eventId}/todo/${payload._id}/check`,
        payload
      )
      payload['todoId'] = payload._id
      payload['checkedAt'] = new Date().toJSON()
      context.dispatch('socket/todo/check', payload)
      if (onlineCallback) return
      context.commit('todo/update', payload)
    },
    //updates one todo by its id
    'todo/update': async (context, payload) => {
      const { onlineCallback } = payload
      if (onlineCallback) delete payload.onlineCallback
      if (!context.rootGetters.online) {
        context.commit('todo/update', payload)
        return context.commit('addToOnlineQueue', {
          command: 'todo/update',
          payload,
        })
      }
      await axios.put(
        `/event/${payload.eventId}/todo/${payload.todoId}`,
        payload
      )
      context.dispatch('socket/todo/update', payload)
      if (onlineCallback) return
      context.commit('todo/update', payload)
    },
    //destroys one todo
    'todo/destroy': async (context, { eventId, todoId, onlineCallback }) => {
      if (!context.rootGetters.online) {
        context.commit('todo/destroy', { eventId, todoId })
        return context.commit('addToOnlineQueue', {
          command: 'todo/destroy',
          payload: { eventId, todoId },
        })
      }
      await axios.delete(`/event/${eventId}/todo/${todoId}`)
      if (onlineCallback) return
      context.commit('todo/destroy', { eventId, todoId })
    },
    //stores one report
    'report/store': async (context, payload) => {
      const { onlineCallback } = payload
      if (onlineCallback) delete payload.onlineCallback
      if (!context.rootGetters.online) {
        const _id = ObjectID().toString()
        context.commit('report/store', { ...payload, _id })
        return context.commit('addToOnlineQueue', {
          command: 'report/store',
          payload: { ...payload, _id },
        })
      }
      let { data } = await axios.post(
        `/event/${payload.eventId}/report`,
        payload
      )
      context.dispatch('socket/report/store', {
        eventId: payload.eventId,
        ...data,
      })
      if (onlineCallback) return
      context.commit('report/store', { eventId: payload.eventId, ...data })
    },
    //updates one report by id
    'report/update': async (context, payload) => {
      const { onlineCallback } = payload
      if (onlineCallback) delete payload.onlineCallback
      if (!context.rootGetters.online) {
        context.commit('report/update', payload)
        return context.commit('addToOnlineQueue', {
          command: 'report/update',
          payload,
        })
      }

      await axios.put(
        `/event/${payload.eventId}/report/${payload.reportId}`,
        payload
      )
      context.dispatch('socket/report/update', payload)
      if (onlineCallback) return
      context.commit('report/update', payload)
    },
    //destroys one report
    'report/destroy': async (
      context,
      { eventId, reportId, onlineCallback }
    ) => {
      if (!context.rootGetters.online) {
        context.commit('report/destroy', { eventId, reportId })
        return context.commit('addToOnlineQueue', {
          command: 'report/destroy',
          payload: { eventId, reportId },
        })
      }

      await axios.delete(`/event/${eventId}/report/${reportId}`)
      context.dispatch('socket/report/destroy', { eventId, reportId })
      if (onlineCallback) return
      context.commit('report/destroy', { eventId, reportId })
    },
    'statusReport/index': async context => {
      const { data } = await axios.get('/event/status-reports')
      context.commit('statusReport/set', data)
    },
    'todo/updateIndexes': async (context, payload) => {
      const { data } = await axios.put('/event/todo/update-indexes/', payload)
      context.commit('events/set', data)
      context.dispatch('socket/todo/updateIndexes', payload.changedItems)
    },
    'eventType/index': async context => {
      const { data } = await axios.get('/event-type')
      context.commit('eventType/set', data)
    },
  },
}

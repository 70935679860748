const he = {
  appTitle: 'צח"י',
  generics: {
    level: 'רמה',
    totalScore: 'סך כל הנקודות',
    score: 'ניקוד | נקודות',
    deleteThe: 'למחוק את',
    the: 'את',
    home: 'בית',
    mainMenu: 'תפריט ראשי',
    change: 'שנה',
    changes: 'שינויים',
    actions: 'פעולות',
    delete: 'למחוק',
    deleteThis: 'למחוק את',
    forceChanges: 'כפה שנויים',
    remove: 'מחיקה',
    name: 'שם',
    show: 'הצג',
    add: 'הוסף',
    save: 'שמור',
    edit: 'ערוך',
    toEdit: 'לערוך',
    search: 'חיפוש',
    new: 'חדש',
    cancel: 'ביטול',
    confirm: 'אישור',
    user: 'משתמש',
    users: 'משתמשים',
    selectColors: 'בחר צבע',
    color: 'צבע',
    escorters: 'מלווים',
    send: 'שלח',
    title: 'כותרת',
    message: 'הודעה',
    deleteAll: 'מחק הכל',
    outbox: 'דואר יוצא',
    urgent: 'דחוף',
    regular: 'רגיל',
    complex: 'מורכב',
    days: 'ימים',
    date: 'תאריך',
    time: 'זמן',
    all: 'הכל',
    status: 'סטטוס',
    approve: 'מאשר',
    decline: 'דחה',
    reject: 'דחה',
    location: 'מיקום',
    before: 'לפני',
    addressee: 'נמען',
    join: 'הצטרפות',
    leave: 'יציאה',
    requestSent: 'בקשתך נשלחה',
    global: 'כללי',
    applicant: 'הפונה',
    type: 'סוג',
    escort: 'ליווי',
    sentAt: 'נשלח בתאריך',
    newEscortRequests: 'בקשת ליווי חדש',
    numberOfPeople: 'מספר נפשות',
    oneTime: 'חד פעמי',
    permanent: 'קבוע',
    supply: 'אספקה',
    orderDetails: 'פרטי הזמנה',
    notes: 'הערות',
    range: 'טווח',
    address: 'כתובת',
    logoutForNoUse: 'פג תוקף ההתחברות שלך, נא להתחבר מחדש',
  },
  days: [
    { name: 'ראשון', key: 'א' },
    { name: 'שני', key: 'ב' },
    { name: 'שלישי', key: 'ג' },
    { name: 'רביעי', key: 'ד' },
    { name: 'חמישי', key: 'ה' },
    { name: 'שישי', key: 'ו' },
    { name: 'שבת', key: 'ז' },
  ],
  date: {
    month: 'חודש',
    week: 'שבוע',
    day: 'יום',
    today: 'היום',
    dateStart: 'תאריך התחלה',
    dateEnd: 'תאריך סיום',
    supplyDate: 'תאריך אספקה',
    productionDate: 'תאריך הפקה',
    creationDate: 'תאריך יצירה',
    deletionDate: 'תאריך מחיקה',
    timeStart: 'זמן התחלה',
    timeEnd: 'זמן סיום',
    minutes: 'דקות',
    monthsNames: [
      'ינואר',
      'פברואר',
      'מרץ',
      'אפריל',
      'מאי',
      'יוני',
      'יולי',
      'אוגוסט',
      'ספטמבר',
      'אוקטובר',
      'נובמבר',
      'דצמבר',
    ],
  },
  drawer: {
    home: 'דף הבית',
  },
  auth: {
    email: 'אימייל',
    password: 'סיסמה',
    login: 'התחברות',
    logout: 'התנתקות',
  },
  bid: {
    index: 'הצעת מחיר | הצעות מחיר',
    create: 'יצירת הצעת מחיר',
    edit: 'עריכת הצעת מחיר',
    askDelete: "למחוק את ההזמנה '{name}'?",
  },
  customer: {
    index: 'לקוח | לקוחות',
    name: 'שם לקוח',
    private: 'פרטי',
    business: 'עסקי',
  },
  delivery: {
    notes: 'הערות על המשלוח',
    servingType: 'צורת הגשה',
    servingTypes: {
      ready: 'מוכן להגשה',
      selfHeating: 'חימום עצמי',
      readyForTomorrow: 'מותאם לאירוע למחרת',
    },
  },
  userManagement: {
    name: 'שם',
    email: 'אימייל',
    phone: 'טלפון',
    password: 'סיסמה',
    role: 'סוג משתמש',
    changePassword: 'שנה סיסמה',
    new: 'הוספת משתמש',
    edit: 'עריכת משתמש',
    createUserSuccessMessage: 'המשתמש נוסף בהצלחה',
    editUserSuccessMessage: 'המשתמש עודכן בהצלחה',
    changeUserPasswordWarning: 'הסיסמא של {name} עומדת להשתנות. אתה בטוח?',
    allUsers: 'כל המשתמשים',
  },
  userDetails: {
    userDetails: 'פרטים אישיים',
    name: 'שם',
    phone: 'טלפון',
    email: 'אימייל',
  },
  errors: {
    validate: {
      complexPaths: 'אנא הכנס שם ולפחות 2 מסלולים',
      paths: 'אנא הכנס שם ולפחות 2 נקודות',
      required: 'שדה חובה',
      minlen6: 'מינימום 6 תוים',
      minlen2: 'מינימום 2 תוים',
      maxlen40: 'מקסימום 40 תוים',
      maxlen100: 'מקסימום 100 תוים',
      choose: 'נא לבחור',
      minAndMaxPassenger: 'בחר מינמום נוסע אחד ומקסימום {max} נוסעים',
    },
    auth: {
      email: 'יש להזין אמייל',
      emailInvalid: 'אימייל שגוי',
      phoneInvalid: 'טלפון שגוי',
      pass: 'יש להזין סיסמה',
      minlen6: 'מינימום 6 תוים',
    },
    notEmpty: 'לא יכול להיות ריק',
    global: 'אירעה שגיאה בלתי צפויה',
    globalShort: 'שגיאה',
    missingItems: 'פרטים חסרים',
    formInvalid: 'נא השלם את כל השדות',
  },
  order: {
    payed: 'שולם',
    waiting: 'ממתין לתשלום',
    'in-progress': 'בהכנה',
    done: 'מוכן',
  },
  eventCategories: {
    fire: 'אירוע לוגיסטי',
    earthQuake: 'אירוע לוגיסטי קולוסלי',
    intruder: 'אירוע בטחוני ביישוב',
    'outside-event': 'אירוע אזרחי ביישוב',
    bombing: 'אירוע מחוץ לישוב',
    carAccident: 'אירוע רב נפגעים',
  },
  statusReport: {
    user: 'משתמש',
    type: 'סוג דיווח',
    content: 'תוכן',
    event: 'אירוע',
    createdAt: 'תאריך יצירה',
    opening_an_event: 'פתיחת אירוע',
    editing_summary_of_event: 'עריכת תקציר האירוע',
    editing_location_on_map: 'עריכת מיקום על המפה',
    closing_event: 'סגירת האירוע',
    sending_messages_to_everyone: 'שליחת הודעות לכולם',
    adding_task_from_admin: 'הוספת משימה אוטומטית ממערכת הניהול',
    adding_task: 'הוספת משימה',
    marking_task_done: 'סימון משימה כבוצעה',
    unmarking_task_done: 'ביטול סימון משימה כבוצעה',
    repeat_mission_opened_again: 'חזרת המשימה נפתחה מחדש',
    edit_task_name: 'עריכת שם המשימה',
    edit_task_notes: 'עריכת הערות למשימה',
    sending_status_report: 'שליחת דו"ח מצב',
    department: 'מכלול',
  },
}
export default he

import dayjs from "dayjs"

const genericMixins = {
  data: function () {
    return {}
  },
  methods: {
    emailValidation(email) {
      let regEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
      return regEx.test(email)
    },
    formatTime(time) {
      return dayjs(time).format("DD.MM.YY בשעה HH:mm")
    },
    timeIsBefore(time) {
      return dayjs().isBefore(dayjs(time))
    },
    extractClapErrorHe(e) {
      if (e && e.response && e.response.data && e.response.data.clapErr) {
        let errObj = e.response.data
        console.log(errObj, errObj.he)
        return errObj.he
      } else {
        return "משהו השתבש"
      }
    },
  },
}

export default genericMixins

import genericMixins from '@/mixins/generic'
import swalMixins from '@/mixins/swal'
import axios from 'axios'

export default {
  state: {
    isAuth: false,
    isAdmin: false,
    team: null,
    username: null,
    role: null,
    isWarm: null,
    teams: [],
  },
  getters: {
    username: state => state.username,
    team: state => state.team,
    isAuth: state => state.isAuth,
    isAdmin: state => state.isAdmin,
    isWarm: state => state.isWarm,
    role: state => state.role,
    teams: state => state.teams,
  },
  mutations: {
    setUserName: (state, payload) => (state.username = payload),
    setTeams: (state, payload) => (state.teams = payload),
    setAdmin: (state, payload) => {
      localStorage.setItem('isAdmin', payload)
      state.isAdmin = payload
    },
    setIsWarm: (state, payload) => (state.isWarm = payload),
    'auth/login': (state, user) => {
      state.isAuth = true
      state.team = user.team
      state.username = user.username
      state.role = user.role
      state.isWarm = user.isWarm
      state.isAdmin = Boolean(JSON.parse(localStorage.getItem('isAdmin')))
    },
    clearData: state => {
      state.isAuth = false
      state.isAdmin = false
      state.team = null
      state.username = null
      state.role = null
      state.isWarm = null
      localStorage.setItem('isAdmin', false)
    },
  },
  actions: {
    'auth/register': async (context, payload) => {
      let { data } = await axios.post('/auth/register', payload)
      context.commit('auth/login', data)
    },
    'auth/login': async (context, payload) => {
      try {
        await axios.post(`/auth/login/${payload.team}`, payload)
        context.commit('auth/login', payload)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
        throw new Error(e.response.data)
      }
    },
    'auth/checkLogin': async context => {
      try {
        let { data } = await axios.get('/auth/checkLogin')
        context.commit('auth/login', data)
      } catch (e) {
        throw new Error()
      }
    },
    'auth/logout': async context => {
      try {
        context.commit('clearData')
        context.commit('clearEvents')
        await axios.post('/auth/logout')
      } catch (e) {
        console.log(e)
        throw new Error(e)
      }
    },
    'auth/teams': async context => {
      let { data } = await axios.get('/auth/teams')
      context.commit('setTeams', data)
    },
  },
}

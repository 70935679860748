<template>
  <v-dialog
    v-model="openModal"
    :max-width="maxWidth"
    :fullscreen="fullscreen"
    :persistent="persistent"
    v-bind="$attrs"
  >
    <v-card color="black" class="rounded-xl">
      <v-toolbar flat color="black">
        <v-spacer />
        <v-btn
          v-if="isCloseable"
          icon
          @click="openModal = false"
          color="primary"
          :disabled="disabled"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="text-center white--text" v-if="title">
        <h1 class="text-center" :class="titleClasses">{{ title }}</h1>
      </v-card-text>
      <v-card-text class="white--text" :class="subtitleClasses" v-if="subtitle">
        <h4>{{ subtitle }}</h4>
      </v-card-text>
      <v-card-text>
        <slot name="content" />
      </v-card-text>

      <v-card-actions>
        <v-row class="px-md-15" dense>
          <v-col>
            <v-btn
              v-if="textBtnRight"
              v-text="textBtnRight"
              class="rounded-lg text-h5"
              :class="!primaryIsLeftBtn ? styleBtnPrimary : styleBtnSecondary"
              x-large
              block="block"
              @click="$emit('actionBtnRight')"
              :disabled="disabled"
            />
          </v-col>
          <v-col>
            <v-btn
              v-if="textBtnLeft"
              v-text="textBtnLeft"
              class="rounded-lg text-h5"
              :class="primaryIsLeftBtn ? styleBtnPrimary : styleBtnSecondary"
              x-large
              block="block"
              @click="$emit('actionBtnLeft')"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MyDialog',
  data: () => ({
    loading: false,
    styleBtnPrimary: 'orange-gradient white--text',
    styleBtnSecondary: 'black--text',
  }),
  props: {
    value: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    isCloseable: {
      type: Boolean,
      default: false,
    },
    primaryIsLeftBtn: {
      type: Boolean,
      default: true,
    },
    title: { type: String, default: '' },
    titleClasses: { type: String, default: 'text-h4' },
    subtitle: { type: String, default: '' },
    subtitleClasses: { type: String, default: 'text-h5' },
    textBtnLeft: {
      type: String,
      default: 'לא',
    },
    textBtnRight: {
      type: String,
      default: 'כן',
    },
    maxWidth: { type: Number, default: 900 },
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

import axios from 'axios'
export default async function handleTokenFromURL() {
  // Extract token parameter from the URL
  const token = new URL(window.location.href).searchParams.get('token')

  if (token) {
    // Clear the localStorage
    localStorage.clear()

    let isValidToken

    try {
      // Delete the 'team' cookie
      deleteCookie('team')

      // Set the 'team' cookie with the extracted token
      document.cookie = `team=${token}`

      // Check if the token is valid by making a request
      await axios.get('/auth/checkLogin')
      isValidToken = true
    } catch {
      isValidToken = false
    }

    // If the token is not valid, delete the 'team' cookie again
    if (!isValidToken) {
      deleteCookie('team')
    }

    // Update the browser history and reload the page
    window.history.replaceState({}, document.title, '/')
    window.location.reload()
  }
}

function deleteCookie(cookieName) {
  // Set the expiration date to a past time to delete the cookie
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

// Call the function to handle the token from the URL when needed
// handleTokenFromURL();

<template>
  <v-card class="event-bubble pa-2 pb-2 rounded-xl" color="secondary">
    <v-container class="ma-0 pb-0">
      <v-row class="flex-row-reverse align-center" v-if="showActionBtn">
        <v-btn
          @click.stop="actionBtn"
          color="white"
          class="font-weight-bold py-5 end-event-btn"
        >
          {{ btnText }}
        </v-btn>
        <v-btn v-if="allowEdit" @click="editEvent" icon>
          <img src="../assets/edit-pencil.png" />
        </v-btn>
      </v-row>
      <v-row>
        <div class="title font-weight-bold orange--text mt-2">
          <v-icon
            class="white--text"
            @click="toggleMap"
            v-click-outside="{
              handler: closeMap,
              include: include,
            }"
            >mdi-map-marker</v-icon
          >
          <span class="text-center text-h6"> {{ eventName }} {{ time }} </span>
        </div>
      </v-row>
      <div v-if="showContent" class="white--text body-1 mt-2 ms-2">
        {{ event && event.content }}
      </div>
    </v-container>
    <GmapMap
      v-if="hasLocation && showMap"
      :center="centerCoordinates"
      :zoom="15"
      map-type-id="terrain"
      style="height: 55vh"
      class="mb-4 included"
    >
      <!-- markers -->
      <GmapMarker
        v-for="(m, i) in markers"
        :key="i"
        :position="m"
        @click="centerCoordinates = m"
      />
    </GmapMap>
  </v-card>
</template>

<script>
import Times from '@/helpers/times.js'
import dayjs from 'dayjs'
export default {
  name: 'event-bubble',
  data() {
    return {
      //default location set to Jerusalem
      centerCoordinates: {
        lat: 31.7683,
        lng: 35.2137,
      },
      showMap: false,
    }
  },
  props: {
    event: { type: Object, default: () => ({}) },
    showActionBtn: { type: Boolean, default: true },
    showContent: { type: Boolean, default: true },
    btnText: { type: String, default: '' },
    allowEdit: { type: Boolean, default: false },
  },
  computed: {
    eventType() {
      return this.$store.getters.eventTypes
    },
    eventName() {
      return this.eventType.find(c => c.name === this.event?.category)?.text
    },
    time() {
      return Times.extractTimeString(this.event?.createdAt)
    },
    date() {
      return dayjs(this.event?.updatedAt).format('DD/MM/YYYY')
    },
    hasLocation() {
      return this.event?.location?.coordinates.length
    },
    markers() {
      if (!this.hasLocation) return []

      return this.event?.location.coordinates.map(cord => ({
        lat: cord[0],
        lng: cord[1],
      }))
    },
  },
  methods: {
    actionBtn() {
      this.$emit('actionBtnClick')
    },
    editEvent() {
      this.$emit('actionEdit')
    },
    toggleMap() {
      this.showMap = !this.showMap
    },
    closeMap() {
      this.showMap = false
    },
    include() {
      return document.querySelector('.included')
        ? [document.querySelector('.included')]
        : []
    },
    setCenterCoordinatesToUser() {
      navigator.geolocation.getCurrentPosition(res => {
        this.centerCoordinates = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        }
      })
    },
  },
  mounted() {
    if (this.hasLocation) {
      this.setCenterCoordinatesToUser()
    }
  },
}
</script>

<style lang="scss" scoped>
.event-bubble {
  width: 90%;
  margin: 0 auto;

  .end-event-btn {
    border-radius: 10px;
  }
}
</style>

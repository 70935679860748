<template>
  <div>
    <my-dialog
      v-model="closeEvent1"
      @actionBtnLeft="closeEvent1 = false"
      @actionBtnRight="toggleDialogCloseEvent"
      :title="`האם לסגור אירוע ${eventName}?`"
      :persistent="false"
      :titleClasses="'text-h4'"
    />
    <my-dialog
      v-model="closeEvent2"
      @actionBtnLeft="closeEvent2 = false"
      @actionBtnRight="submit"
      :titleClasses="'text-h4'"
      :title="`האם אתה בטוח? סגירת אירוע היא בלתי הפיכה`"
    />
  </div>
</template>

<script>
import MyDialog from './MyDialog.vue'

export default {
  name: 'close-event-dialog',
  components: { MyDialog },
  props: {
    value: Boolean,
  },
  data() {
    return {
      closeEvent2: false,
    }
  },
  computed: {
    eventType() {
      return this.$store.getters.eventTypes
    },
    closeEvent1: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    event() {
      return this.$store.getters.event
    },
    eventName() {
      return this.eventType.find(c => c.name === this.event?.category)?.text
    },
    category() {
      return this.event?.category
    },
  },
  methods: {
    toggleDialogCloseEvent() {
      this.closeEvent2 = true
      this.closeEvent1 = false
    },
    async submit() {
      await this.$store.dispatch('event/close', { _id: this.event?._id })
      this.closeEvent2 = false
    },
  },
}
</script>

<style></style>

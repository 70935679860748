<template>
  <v-container>
    <multi-event @actionBtnClick="openCloseEvent" @actionEdit="editEvent" />
    <v-row>
      <v-col class="d-flex justify-center align-center">
        <h1>פתיחת אירוע חדש</h1>
      </v-col>
    </v-row>
    <v-row v-for="(item, i) in items" :key="i" justify="center">
      <v-col cols="10" class="my-0 py-2 text-center">
        <menu-btn
          :text="item.text"
          :additional="item.additional"
          @click="action(item)"
        />
      </v-col>
    </v-row>
    <close-event-dialog v-model="showCloseEvent" />
    <open-event-dialog
      v-if="openEventDialog"
      v-model="openEventDialog"
      :category="category"
      :isNew="isNew"
    />
  </v-container>
</template>

<script>
import MenuBtn from '@/components/MenuBtn.vue'
import MultiEvent from '../components/MultiEvent.vue'
import CloseEventDialog from '@/components/dialogs/CloseEventDialog.vue'
import OpenEventDialog from '@/components/dialogs/OpenEventDialog.vue'
import onesignalMixins from '@/mixins/onesignal'
export default {
  name: 'dashboard-admin',
  components: {
    MenuBtn,
    MultiEvent,
    CloseEventDialog,
    OpenEventDialog,
  },
  mixins: [onesignalMixins],
  data() {
    return {
      loading: false,
      showCloseEvent: false,
      openEventDialog: false,
      isNew: true,
      category: null,
    }
  },
  computed: {
    items() {
      return this.$store.getters.eventTypes
    },
    events() {
      return this.$store.getters.events
    },
    isEventsOpen() {
      return this.events.length > 0
    },
    isEventOpen() {
      return this.events.length > 1
    },
  },
  methods: {
    action(category) {
      this.category = category.name
      this.isNew = true
      this.openEventDialog = true
    },
    openCloseEvent() {
      this.showCloseEvent = true
    },
    editEvent() {
      this.isNew = false
      this.openEventDialog = true
    },
  },
}
</script>

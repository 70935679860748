<template>
  <div>
    <my-dialog title="רשימת דוחות" v-model="openModal">
      <template v-slot:content>
        <v-row v-for="(report, i) in reportsSorted" :key="report._id">
          <v-col>
            <v-badge
              left
              :value="!!report.isNew"
              color="light-green accent-3"
              style="width: 100%"
              dot
              offset-x="5"
              offset-y="5"
              class="px-3 pt-3 show-reports rounded-lg"
            >
              <h4>
                דו”ח מצב {{ lastReportTitle(i) }} ({{ report.username }} -
                {{ categoryText(report.category) }}
                {{ time(report.createdAt) }}):
              </h4>
              <p>
                {{ report.content }}
              </p>
            </v-badge>
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn
          text
          color="primary"
          v-text="'סגור'"
          @click="openModal = false"
        />
      </template>
    </my-dialog>
  </div>
</template>

<script>
import Times from '@/helpers/times'
import genericsServices from '@/services/generics'
import MyDialog from '../generics/MyDialog.vue'
import { categories } from '@/helpers/categoriesAndTypes'
export default {
  name: 'all-reports-dialog',
  components: { MyDialog },
  props: {
    value: Boolean,
    reports: Array,
  },

  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    reportsSorted() {
      let reports = [...this.reports]
      return reports.sort(genericsServices.sortByKey('createdAt')) || []
    },
  },
  methods: {
    categoryText(categoryInput) {
      return categories.find(category => category.category == categoryInput)
        ?.text
    },
    time(time) {
      return Times.extractTimeString(time)
    },
    lastReportTitle(index) {
      return index === 0 ? 'אחרון' : ''
    },
  },
  beforeDestroy() {
    this.$store.commit('counterReport/destroyByEventId', [
      ...this.reports.map(r => r.eventId),
    ])
  },
}
</script>

<style lang="scss" scoped>
.show-reports {
  border: 1px solid var(--orange);
  background-color: var(--background);
}
</style>

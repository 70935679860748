<template>
  <div v-if="isMultiEvents" class="d-flex align-center">
    <v-btn icon color="black" @click="nextEvent">
      <v-icon fab>mdi-chevron-right</v-icon>
    </v-btn>
    <event-bubble :event="event" :btnText="isAdminDashboard ? 'סיום אירוע' : 'שליחת דוח מצב'"
      :allowEdit="isAdminDashboard" :showActionBtn="showActionButton" @actionBtnClick="actionBtnClick"
      @actionEdit="actionEdit" />
    <v-btn icon color="black" @click="backEvent">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
  </div>
  <!-- <v-row v-if="isMultiEvents" dense>
    <v-col class="d-flex align-center">
      <v-btn icon color="black" @click="nextEvent">
        <v-icon fab>mdi-chevron-right</v-icon>
      </v-btn>
      <event-bubble :event="event" :btnText="isAdminDashboard ? 'סיום אירוע' : 'שליחת דוח מצב'"
        :allowEdit="isAdminDashboard" :showActionBtn="showActionButton" @actionBtnClick="actionBtnClick"
        @actionEdit="actionEdit" />
      <v-btn icon color="black" @click="backEvent">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-col>
  </v-row> -->
  <event-bubble v-else-if="event" :event="event" :btnText="isAdminDashboard ? 'סיום אירוע' : 'שליחת דוח מצב'"
    :allowEdit="isAdminDashboard" :showActionBtn="showActionButton" @actionBtnClick="actionBtnClick"
    @actionEdit="actionEdit" />
  <div v-else></div>
</template>

<script>
import EventBubble from "./EventBubble.vue"
export default {
  name: "multi-event",
  components: { EventBubble },
  props: { showActionBtn: { type: Boolean, default: false } },
  data() {
    return {
      eventIndex: 0,
    }
  },
  computed: {
    isAdminDashboard() {
      return this.$route.name === "DashboardAdmin"
    },
    showActionButton() {
      return this.showActionBtn || this.isAdminDashboard
    },
    events() {
      return this.$store.getters.events
    },
    event() {
      return this.$store.getters.event
    },
    isMultiEvents() {
      return this.events.length > 1
    },
    leftEvent() {
      const index = this.eventIndex ? this.eventIndex : this.events.length
      return this.events[index - 1]
    },
    rightEvent() {
      const index =
        this.eventIndex === this.events.length - 1 ? -1 : this.eventIndex
      return this.events[index + 1]
    },
  },
  methods: {
    actionBtnClick() {
      this.$emit("actionBtnClick")
    },
    actionEdit() {
      this.$emit("actionEdit")
    },
    nextEvent() {
      this.eventIndex =
        this.eventIndex === this.events.length - 1 ? 0 : this.eventIndex + 1
    },
    backEvent() {
      this.eventIndex =
        this.eventIndex === 0 ? this.events.length - 1 : this.eventIndex - 1
    },
  },
  mounted() {
    if (this.events.length === 1) {
      this.$store.commit("event/set", this.events[0])
    }
  },
  watch: {
    eventIndex() {
      this.$store.commit("event/set", this.events[this.eventIndex])
    },
  },
}
</script>

<style>

</style>

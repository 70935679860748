<template>
  <v-row>
    <v-col>
      <v-row v-if="isMultiEvents" align="center" justify="center" dense>
        <v-col class="d-flex" cols="8">
          <select-event />
        </v-col>
      </v-row>
      <v-carousel v-model="categoriesIndex" hide-delimiters height="100">
        <v-carousel-item v-for="category in categories" :key="category.text">
          <v-row dense>
            <v-col cols="3" class="text-end">
              {{ leftCategories.text }}
            </v-col>
            <v-col class="text-center text-h5 orange--text">
              {{ category.text }}
            </v-col>
            <v-col cols="3">
              {{ rightCategories.text }}
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-col>
  </v-row>
</template>

<script>
import { categories } from "@/helpers/categoriesAndTypes"
import SelectEvent from "./SelectEvent.vue"
export default {
  components: { SelectEvent },
  name: "categories-carousel",
  props: {
    category: String,
  },
  data() {
    return {
      categoriesIndex: 0,
    }
  },
  computed: {
    isMultiEvents() {
      return this.$store.getters.events.length > 1
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    categories() {
      return categories
    },
    leftCategories() {
      const index = this.categoriesIndex
        ? this.categoriesIndex
        : this.categories.length
      return this.categories[index - 1]
    },
    rightCategories() {
      const index =
        this.categoriesIndex === this.categories.length - 1
          ? -1
          : this.categoriesIndex
      return this.categories[index + 1]
    },
  },
  mounted() {
    if (this.category) {
      this.$emit("setCategories", this.category)
    } else {
      this.$emit("setCategories", this.categories[this.categoriesIndex])
    }
  },
  watch: {
    categoriesIndex() {
      this.$emit("setCategories", this.categories[this.categoriesIndex])
    },
  },
}
</script>

<style></style>

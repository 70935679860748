import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './modules/authModule'
import eventModule from './modules/eventModule'
import counterModule from './modules/counterModule'
import socketModule from './modules/socketModule'
import offlineModule from './modules/offlineModule'
import onesignalModule from './modules/onesignalModule'
import { version } from '../../package.json'

Vue.use(Vuex)

const modules = {
  auth: authModule,
  event: eventModule,
  counter: counterModule,
  socket: socketModule,
  offline: offlineModule,
  onesignal: onesignalModule,
}

let vuexData = {
  state: {
    title: 'zahi',
    isMuted: false,
    backgroundImage: '/images/Artboard_2@2x.png',
    version,
  },
  getters: {
    title: state => state.title,
    isMuted: state => state.isMuted,
    backgroundImage: state => state.backgroundImage,
  },
  mutations: {
    toggleIsMuted: state => (state.isMuted = !state.isMuted),
    setBackgroundImage: (state, payload) =>
      (state.backgroundImage = `/images/${payload}.png`),
    initialiseStore(state) {
      // Check if the ID exists
      if (localStorage.getItem('store')) {
        //parse store
        const storeInLocalStorage = JSON.parse(localStorage.getItem('store'))
        if(storeInLocalStorage.version !== version) {
          localStorage.removeItem('store')
          return
        }
        this.replaceState(
          Object.assign(state,storeInLocalStorage)
        )
      }
    },
  },
  modules,
}

const store = new Vuex.Store(vuexData)

store.subscribe((mutation, state) => {
  if (mutation.type === 'initialiseStore') return
  // Store the state object as a JSON string
  localStorage.setItem('store', JSON.stringify(state))
})

export default store

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>דוחות להורדה</v-card-title>
          <v-card-text>
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="primary"
              style="margin: 0 auto; display: block"
            ></v-progress-circular>
            <span v-else-if="!files.length">לא נמצאו נתונים</span>
            <v-list v-else>
              <v-list-item
                v-for="(file, index) in files"
                :key="index"
                style="border-bottom: solid 1px black"
                :style="index === files.length - 1 ? 'border-bottom: none' : ''"
              >
                <v-list-item-icon>
                  <v-icon>mdi-file</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ file.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    dayjs(file.createdAt).format('DD/MM/YYYY')
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="downloadExcel(file)">
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import downloadExcel from '@/services/downloadStatusReport'
import dayjs from 'dayjs'
export default {
  data() {
    return {
      loading: true,
      dayjs,
    }
  },
  computed: {
    files() {
      return this.$store.getters.statusReports
    },
  },
  methods: {
    downloadExcel({ _id }) {
      downloadExcel(_id)
    },
  },
  async created() {
    await this.$store.dispatch('statusReport/index')
    this.loading = false
  },
}
</script>

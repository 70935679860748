// import axios from "axios"
import Sound from '@/services/sound'
import Cookies from 'js-cookie'

export default {
  actions: {
    // events methods for use with in the actions module
    async set_event({ commit }, id) {
      await commit('event/findByIdAndSet', id)
    },
    async index_events({ dispatch }) {
      await dispatch('event/index', {
        toastOptions: { disabled: true },
      })
    },

    /**
     *  sockets all events emit form server
     * @param {context} vuex context object
     * @param {any} socket data
     */

    /**
     * @param {Object} todo inside object params: eventId and todoId and todo object
     */
    // todo`s update
    async socket_updateTodo({ commit }, todo) {
      commit('todo/update', todo)
    },
    async socket_checkTodo({ commit, getters }, todo) {
      if (getters.isAdmin) {
        if (todo.isChecked) {
          Sound.play('/sound/checkTodo.mp3', getters.isMuted)
          commit('counterTodo/store', todo)
        } else {
          commit('counterTodo/remove', todo)
        }
      }
      commit('todo/update', todo)
    },
    /**
     * @param {Object} todo inside object params: eventId and todoId and todo object
     */
    // todo`s created
    async socket_storeTodo({ commit, getters }, todo) {
      if (getters.isAdmin) {
        Sound.play('/sound/createTodo.mp3', getters.isMuted)
        commit('counterTodo/store', todo)
      }
      commit('todo/store', todo)
    },

    // update one event
    async socket_updateEvent({ commit }, event) {
      commit('event/update', event)
    },
    // updates all events
    async socket_updateEvents({ commit }, events) {
      await commit('events/update', events)
    },
    // event created
    async socket_storeEvent({ commit }, event) {
      commit('event/store', event)
    },
    // event close
    async socket_closeEvent({ commit }, id) {
      commit('event/destroy', id)
    },
    /**
     * @param {Object} report inside object params: eventId and reportId and report object
     */
    // report update
    async socket_storeReport({ commit, getters }, report) {
      if (getters.isAdmin) {
        commit('counterReport/store', report)
      }
      if (report.createdByAdmin || getters.isAdmin) {
        Sound.play('/sound/createReport.mp3', getters.isMuted)
      }
      commit('report/store', report)
    },
    async socket_updateIndexes({ commit }, changedIndexes) {
      commit('todo/updateIndexes', changedIndexes)
    },

    /**
     * sockets coll server emit
     * @param {object} vuex params
     * @param {any} params send to socket
     */

    emitSocketEvent() {
      this._vm.$socket.client.emit('storeTodo')
    },
    'socket/join'() {
      const c = Cookies.get('team')
      if (c) this._vm.$socket.client.emit('auth/join', { cookie: c })
    },
    // events sockets
    'socket/event/store'(context, { event, isPushNotification }) {
      this._vm.$socket.client.emit('event/store', { event, isPushNotification })
    },
    'socket/event/update'(context, event) {
      this._vm.$socket.client.emit('event/update', event)
    },
    'socket/events/update'(context, event) {
      this._vm.$socket.client.emit('events/update', event)
    },
    'socket/event/close'(context, id) {
      this._vm.$socket.client.emit('event/close', id)
    },
    /**
     * report sockets
     * @param {Object} report object
     */
    'socket/report/store'(context, report) {
      this._vm.$socket.client.emit('report/store', report)
    },
    // todo sockets
    'socket/todo/update'(context, todo) {
      this._vm.$socket.client.emit('todo/update', todo)
    },
    'socket/todo/store'(context, todo) {
      this._vm.$socket.client.emit('todo/store', todo)
    },
    'socket/todo/check'(context, todo) {
      this._vm.$socket.client.emit('todo/check', todo)
    },
    'socket/todo/updateIndexes'(context, changedIndexes) {
      this._vm.$socket.client.emit('todo/updateIndexes', changedIndexes)
    },
  },
}

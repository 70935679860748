<template>
  <v-card class="my-5 py-3 px-5" :style="`background-color:${eventColor};`">
    <v-badge
      left
      :value="!!todo.isNew"
      offset-x="-7"
      color="light-green accent-3"
      style="width: 100%"
      dot
    >
      <v-row
        class="d-flex align-center flex-nowrap"
        justify="space-between"
        style="position: relative"
      >
        <v-icon v-if="isCheckAble" large class="ms-n2 drag-icon"
          >mdi-drag</v-icon
        >
        <v-col cols="1" class="d-flex flex-column align-center">
          <template v-if="isCheckAble">
            <div
              v-if="todo.isChecked"
              style="position: absolute; top: 5px"
              class="caption font-weight-regular black--text"
            >
              סומן על ידי : {{ todo.checkedBy }}
            </div>
            <v-checkbox
              :input-value="todo.isChecked"
              @change="updateStatus"
              :color="eventIndex <= 4 ? 'white' : 'azure'"
              class="ms-n2"
            />
          </template>
          <template v-else-if="todo.isChecked">
            <div class="center-all-col">
              <v-icon color="white" large> mdi-check-bold </v-icon>
              <div class="caption font-weight-regular black--text">
                {{ checkedAtFormatted }}
              </div>
              <div class="caption font-weight-regular black--text">
                {{ todo.checkedBy }}
              </div>
            </div>
          </template>
          <!-- <template v-else>
          <div class="center-all-col">
            <v-icon color="red" large> mdi-close </v-icon>
          </div>
        </template> -->
        </v-col>
        <v-col cols="8">
          <div style="width: calc(100% - 15px)">
            <div class="text-body font-weight-bold">{{ todo.title }}</div>
            <div>{{ todo.comment }}</div>
          </div>
        </v-col>
        <v-col cols="2" class="d-flex justify-end">
          <v-btn icon v-if="todo.link">
            <v-icon v-if="!isPhone" @click="openLink" color="orange">
              mdi-link-variant
            </v-icon>
            <v-icon v-else @click="openLink" color="light-green accent-3">
              mdi-phone
            </v-icon>
          </v-btn>
          <v-btn icon v-if="isEditable" @click="edit">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-badge>
  </v-card>
</template>
<script>
import times from '@/helpers/times.js'
import { getKeyFromDB } from '@/services/FilesSave'
import mim from 'mime-types'
export default {
  name: 'to-do',
  props: {
    todo: { type: Object, required: false },
    isEditable: { type: Boolean, default: true },
    isCheckAble: { type: Boolean, default: true },
    username: { type: String, default: '' },
    colors: {
      type: Array,
      default: () => ['#4285F4', '#DB4437', '#F4B400', '#0F9D58', '#50C7C7'],
    },
  },
  computed: {
    checkedAtFormatted() {
      if (this.todo?.checkedAt)
        return times.extractTimeString(this.todo.checkedAt)
      else return ''
    },
    eventId() {
      return this.$store.getters.event._id
    },
    events() {
      return this.$store.getters.events
    },
    eventColor() {
      return this.colors[this.eventIndex]
    },
    eventIndex() {
      return this.events.findIndex(event => event._id === this.todo.eventId)
    },
    isWebLink() {
      const { link } = this.todo
      return link?.startsWith('http') || link?.startsWith('www')
    },
    isPhone() {
      const isNumber = Number(this.todo?.link)
      return !!isNumber
    },
    linksNotFile() {
      return this.$store.getters.linksNotFile
    },
    // check if is Link Or Phone
    isLink() {
      if (this.todo?.link.includes('http')) return true
      return false
    },
  },
  methods: {
    downloadBlobFile(blob, fileName) {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = fileName + `.${mim.extension(blob.type)}`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    },
    async openLink() {
      const { link } = this.todo
      if (!this.$store.getters.online && !this.linksNotFile[link]) {
        const fileInDb = await getKeyFromDB(link)
        if (fileInDb) {
          const { file } = fileInDb
          this.downloadBlobFile(file, link)
          return
        }
      }
      const noHttp = this.isPhone || link.startsWith('http') ? '' : 'https://'

      window.open(
        `${this.isPhone ? 'tel:' : ''}${noHttp}${this.todo?.link}`,
        '_blank'
      )
    },
    edit() {
      this.$emit('edit')
    },
    async updateStatus(isChecked) {
      const data = {
        ...this.todo,
        isChecked,
        checkedBy: this.$store.getters.username,
        eventId: this.todo?.eventId,
      }
      await this.$store.dispatch('todo/check', data)
    },
  },
}
</script>

<template>
  <my-dialog
    v-model="model"
    @actionBtnLeft="closeDialog"
    @actionBtnRight="addTodo"
    :textBtnLeft="'ביטול'"
    :textBtnRight="'הוספה'"
    :primaryIsLeftBtn="false"
  >
    <template v-slot:content>
      <v-form ref="form">
        <v-text-field
          v-model="form.title"
          label="תוכן המשימה"
          solo
          outlined
          :rules="[v => !!v || 'יש להזין תוכן המשימה']"
        />
        <v-text-field v-model="form.comment" solo label="רשום הערה" />
        <v-select
          v-if="multipleEventsToChoose"
          v-model="chosenEvent"
          :items="event"
          solo
          outlined
          :item-value="event => event"
          :item-text="event => $t(`eventCategories.${event.category}`)"
          label="יש לבחור אירוע"
          :rules="[v => !!v || 'יש לבחור אירוע']"
        >
          <template v-slot:item="{ item }">
            <span class="me-1">
              {{ $t(`eventCategories.${item.category}`) }}</span
            >
            -
            <span class="ms-1">{{ time(item.createdAt) }}</span>
          </template>
        </v-select>
        <v-text-field
          v-if="showAddLink"
          v-model="form.link"
          solo
          label="הוסף קישור"
        />
        <!-- add link btn -->
        <v-btn
          v-if="!showAddLink"
          @click="showAddLink = true"
          color="secondaryLight font-weight-bold mb-5"
        >
          <span class="ml-2">הוספת קישור</span>
          <v-icon color="orange">mdi-link-variant</v-icon>
        </v-btn>
        <v-btn
          v-else
          @click="showAddLink = false"
          color="secondaryLight font-weight-bold mb-5"
        >
          <span class="ml-2">הסר קישור</span>
          <v-icon color="orange">mdi-link-variant</v-icon>
        </v-btn>
        <!-- repeat timer -->
        <v-row class="white--text" dense no-gutters>
          <v-col cols="2" class="d-flex align-start mt-0 pt-0">
            <v-switch
              v-model="form.isRecurring"
              color="orange"
              class="mt-1"
              inset
              hide-details
            />
          </v-col>
          <v-col cols="10">
            <v-row class="align-center" dense>
              <v-col cols="3" class="px-2 d-flex justify-end">
                <span class="d-inline-block"> חזרה כל</span>
              </v-col>
              <v-col cols="4" class="px-2">
                <vue-numeric-input
                  v-model="form.recurringLength"
                  :disabled="!form.isRecurring"
                  :max="1440"
                  :min="1"
                  :step="1"
                  style="background-color: white"
                  width="80px"
                  controlsType="updown"
                  align="center"
                />
              </v-col>
              <v-col cols="4" class="px-3 mr-4">
                <span class="d-inline-block">דקות</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </my-dialog>
</template>

<script>
import MyDialog from '../dialogs/MyDialog.vue'
import Times from '@/helpers/times.js'
export default {
  name: 'add-todo',
  components: {
    MyDialog,
  },
  props: {
    value: { type: Boolean, default: false },
    isNew: { type: Boolean, default: false },
    event: { type: [Array, Object], default: () => ({}) },
    category: { type: String, default: '' },
    todo: { type: [Array, Object] },
  },
  data() {
    return {
      chosenEvent: null,
      showAddLink: false,
      form: {
        isRecurring: false,
        recurringLength: null,
        title: null,
        comment: null,
        link: null,
      },
    }
  },
  methods: {
    time(date) {
      return Times.extractTimeString(date)
    },
    async addTodo() {
      if (!this.$refs.form.validate()) return
      const data = {
        eventId: this.multipleEventsToChoose
          ? this.chosenEvent?._id
          : this.event._id,
        category: this.category,
        todoId: this.todo?._id ? this.todo._id : undefined,
        ...this.form,
      }
      const actionType = this.todo ? 'todo/update' : 'todo/store'
      await this.$store.dispatch(actionType, data)
      this.closeDialog()
    },
    closeDialog() {
      this.model = false
      this.$emit('close')
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    multipleEventsToChoose() {
      if (Array.isArray(this.event)) return true
      return false
    },
  },
  created() {
    if (this.todo) {
      this.form = this.todo
    }
  },
  mounted() {
    if (!this.isNew) {
      this.form = { ...this.form, ...this.todo }
    }
  },
}
</script>

<style lang="scss" scoped>
//the only way to change vuetify's switch color (belive me i have checked...)
.v-input--switch__track {
  color: var(--white) !important;
}
.v-input--is-label-active
  .v-input--selection-controls__input
  .v-input--switch__track {
  color: var(--white) !important;
}
</style>
